import React from "react";
import BackToLobbyButton from "./BackToLobbyButton";
import GamePinDisplay from "./GamePinDisplay";
import NameList from "./NameList";
import ShareLink from "./ShareLink";
import CurrentPlaylist from "../../components/CurrentPlaylist";
import "../../styles/ToggleSwitch.css";

function Lobby({
	requiresLogin,
	logout,
	lobbyId,
	gameData,
	isGameLeader,
	handleStartGame,
	handleToggleChange,
	blindModeTogglerState,
	styles,
	readyToStart,
}) {
	return (
		<>
			{/*<BackToLobbyButton />*/}
			{/* <div
				style={{
					width: "100%",
					position: "relative",
					// paddingTop: "10px",
					top: "69px",
					color: "white",
				}}
			>
				<ShareLink />
			</div> */}

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr", // Creates three equal columns
					alignItems: "flex-end", // Centers items vertically
					width: "100%",
					// background: "green",
				}}
			>
				<div style={{ textAlign: "left" }}>
					<ShareLink />
				</div>
				<div style={{ textAlign: "center" }}>
					<GamePinDisplay gamePin={lobbyId} />
				</div>
				<div style={{ textAlign: "right" }}>
					{/* Another component or leave empty */}
				</div>
			</div>

			<div></div>
			{gameData && (
				<>
					{/* <CurrentPlaylist requiresLogin={requiresLogin} logout={logout} /> */}
					<NameList
						names={gameData.players.map((player) => player.playerName)}
					/>
					<div
						style={{
							width: "100%",
							// background: "red",
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							// position: "absolute",
							// bottom: "150px",
						}}
					>
						{isGameLeader ? (
							<>
								{requiresLogin && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											marginTop: "10px",
										}}
									>
										<div
											style={{
												color: "white",
												marginRight: "5px",
												fontSize: "20px",
											}}
										>
											{blindModeTogglerState ? "Blind Mode" : "Blind Mode"}
										</div>
										<label className="toggle-switch">
											<input
												type="checkbox"
												checked={blindModeTogglerState}
												onChange={handleToggleChange}
											/>
											<span className="slider"></span>
										</label>
									</div>
								)}

								{readyToStart ? (
									<button
										onClick={handleStartGame}
										className={`${styles.join} ${styles.button} slide-in`}
										key="start-game-button"
									>
										START GAME
									</button>
								) : (
									<div style={{ color: "white" }}></div>
								)}
							</>
						) : (
							<div className={styles.textColor} style={{}}>
								Waiting for {gameData.players && gameData.players[0].playerName}{" "}
								to start...
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default Lobby;
