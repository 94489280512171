import React, { useEffect, useState } from "react";
import styles from "../../styles/MainMenu.module.css";
import AppLogo from "./AppLogo";
import AppDescription from "./AppDescription";
import SpotifyLoginButton from "./SpotifyLoginButton";
import LogoutButton from "./LogoutButton";
import { useSpotifyAuth } from "../../services/SpotifyAuth";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "./PopUp";
import { serverIp, reactPort, backEndAddress } from "../../config";
import InputField from "./InputField";
import PhoneDisplayPanel from "../../components/PhoneDisplayPanel";
import PlaylistSubmitComponent from "./PlaylistSubmitComponent";
import HowToPlay from "./HowToPlay";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";
import GamemodeSwitcher from "./GamemodeSwitcher";
import InfoButtons from "./InfoButtons";
import ContactButton from "./ContactButton";
import OpenSpotify from "./OpenSpotify";
import GoogleAd from "../../components/GoogleAd";
import CurrentPlaylist from "../../components/CurrentPlaylist";

const LandingPage = ({
	selectedGameMode = "asongus",
	requiresLogin = true,
}) => {
	const callback2 = process.env.REACT_APP_FRONTEND_URL + "/asongus/";
	const { goToLobby } = useParams();
	const { token, loggedInWithPlaylist, loginWithPlaylist, loginUrl, logout } =
		useSpotifyAuth(callback2, popUpWithMessage);

	function popUpWithMessage(message, closeLink = "", closeText = "Close") {
		setPopUpText(message);
		setPopUpCloseLink(closeLink);
		setPopUpCloseText(closeText);
		setIsPopupOpen(true);
	}

	const [popUpText, setPopUpText] = useState("Could not find that game.");
	const [popUpCloseLink, setPopUpCloseLink] = useState("");
	const [popUpCloseText, setPopUpCloseText] = useState("Close");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [socket, setSocket] = useState(null);

	const [name, setName] = useState(window.localStorage.getItem("name") || "");
	const [pin, setPin] = useState("");

	const [displayEnterPinScreen, setDisplayEnterPinScreen] = useState(false);

	//Animation triggers
	const [shake, setShake] = useState(false);
	const [transitioningForward, setTransitioningForward] = useState(false);
	const [goingBack, setGoingBack] = useState(false);

	const [createGameSubmitted, setCreateGameSubmitted] = useState(false);
	const [joinSubmitted, setJoinSubmitted] = useState(false);

	const navigate = useNavigate();

	//check for update to the latest version, reset the songs
	useEffect(() => {
		// Check if the "songsReset" flag exists in localStorage
		const hasResetSongs = window.localStorage.getItem("songsReset3");

		// If the flag does not exist, reset "songs" and set the flag
		if (!hasResetSongs) {
			const songs = window.localStorage.getItem("songs");
			if (songs) {
				window.localStorage.setItem("songs", null);
				window.localStorage.setItem("songsReset3", true); // Mark as done
				window.location.reload();
			} else {
				window.localStorage.setItem("songsReset3", true);
			}
		}
	}, []); // Empty dependency array ensures this runs only once on mount

	useEffect(() => {
		if (token) {
			const newSocket = io(process.env.REACT_APP_BACKEND_URL);
			setSocket(newSocket);

			// Cleanup when the component unmounts or when the token changes
			return () => {
				newSocket.disconnect();
			};
		}
	}, [token]);

	useEffect(() => {
		if (socket) {
			socket.on("joinedGame", (data) => {
				const pin = JSON.parse(data)["pin"];
				window.localStorage.setItem("currentGamePin", pin);
				socket.disconnect();
				navigate(`/asongus/lobby/${pin}`);
			});

			socket.on("gameNotFound", () => {
				shakeTextField();
				popUpWithMessage("Could not find that game.");
			});

			return () => {
				socket.off("gameCreated");
			};
		}
	}, [navigate, socket]);

	const handleNameChange = (event) => {
		window.localStorage.setItem("name", event.target.value);
		setName(event.target.value);
	};

	const handlePinChange = (event) => {
		setPin(event.target.value);
	};

	const handleCreateGame = async () => {
		if (!name) {
			shakeTextField();
			return;
		}

		setCreateGameSubmitted(true);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/create${
					selectedGameMode === "charts-game" ? "Charts" : ""
				}Game`,
				{
					method: "POST",
				}
			);

			if (!response.ok) {
				popUpWithMessage(
					"Server responded with an error: " + response.statusText
				);
				console.error("Server responded with an error:", response.statusText);
				return;
			}

			const data = await response.json();
			console.log("Response from server:", data);

			navigate(
				`/${
					selectedGameMode === "charts-game" ? "charts-game" : "asongus"
				}/lobby/${data.gamePin}`
			);
		} catch (error) {
			popUpWithMessage("Server down, allow 50 seconds to reboot");
			console.error("Error:", error);
		}
		setCreateGameSubmitted(false);
	};

	const shakeTextField = () => {
		setShake(true);
		setTimeout(() => {
			setShake(false);
		}, 500);
	};

	const goToEnterPinScreen = () => {
		setDisplayEnterPinScreen(true);
		setTransitioningForward(true);
		setTimeout(() => {
			setTransitioningForward(false);
		}, 500);
	};

	const goBackToEnterNameScreen = () => {
		setDisplayEnterPinScreen(false);
		setGoingBack(true);
		setTimeout(() => {
			setGoingBack(false);
		}, 500);
		setPin("");
	};

	const handleJoinGame = async () => {
		if (!name) {
			shakeTextField();
		} else if (!displayEnterPinScreen) {
			goToEnterPinScreen();
		} else if (!pin) {
			shakeTextField();
		} else {
			setJoinSubmitted(true);
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/checkGameExists/${pin}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				if (!response.ok) {
					popUpWithMessage(
						"Server responded with an error: " + response.statusText
					);
					console.error("Server responded with an error:", response.statusText);
					return;
				}

				const data = await response.json();
				console.log("Response from server:", data);
				if (data.exists && data.type) {
					navigate(`/${data.type}/lobby/${pin}`);
				} else {
					shakeTextField();
					popUpWithMessage("Could not find that game.");
				}
			} catch (error) {
				popUpWithMessage("Server Down, allow 50 seconds to reboot");
				console.error("Error:", error);
			}
			setJoinSubmitted(false);
		}
	};

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();

	useEffect(() => {
		if (loggedInWithPlaylist) {
			const goToLobby = query.get("goToLobby");

			if (goToLobby) {
				navigate("/asongus/enter-name/" + "?goToLobby=" + goToLobby);
			}
		}
	}, [loggedInWithPlaylist, goToLobby]);

	const [imageLoaded, setImageLoaded] = useState(false);

	return (
		<>
			<PopUp
				isOpen={isPopupOpen}
				content={<p>{popUpText}</p>}
				onClose={() => setIsPopupOpen(false)}
				closeLink={popUpCloseLink}
				closeText={popUpCloseText}
			/>

			<GamemodeSwitcher
				modes={["asongus", "charts-game"]}
				selectedMode={selectedGameMode}
			/>

			{/* <iframe
				allow="autoplay *; encrypted-media *;"
				frameBorder="0"
				height="450"
				style={{
					width: "100%",
					maxWidth: "660px",
					overflow: "hidden",
					background: "transparent",
				}}
				sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
				src="https://embed.music.apple.com/us/album/song-name/id1234567890"
				title="Apple Music Embed"
			></iframe> */}
			<div
				style={{ position: "relative" }}
				className={requiresLogin && !loggedInWithPlaylist && "slide-in"}
			>
				<PhoneDisplayPanel width={"89%"} backgroundColor={"#2e2e2e"}>
					{/* <CurrentPlaylist requiresLogin={requiresLogin} logout={logout} /> */}

					<img
						src={
							selectedGameMode === "asongus"
								? "/images/centered.png"
								: "/images/region_mx_default.jpg"
						}
						style={{ marginTop: "15px", position: "relative" }}
						alt="App Logo"
						className={styles.logo + " slide-down"}
						onLoad={() => setImageLoaded(true)}
					/>

					{imageLoaded && (
						<>
							<div
								className={
									!(requiresLogin && !loggedInWithPlaylist) && "slide-in"
								}
								style={{ position: "relative" }}
							>
								<p style={{}} className={styles.description}>
									{selectedGameMode === "asongus"
										? "The social deduction game connected to your Spotify (or Apple Music)"
										: "Guess what country the song is charting in!"}
								</p>
							</div>

							{requiresLogin && !token && !loggedInWithPlaylist ? (
								<>
									{/* <SpotifyLoginButton loginUrl={loginUrl} />
								<div
									style={{
										color: "lightgray",
										fontWeight: "bold",
										fontSize: "15px",
									}}
								>
									OR
								</div> */}
									<PlaylistSubmitComponent
										submitToServer={loginWithPlaylist}
										popUpWithMessage={popUpWithMessage}
									/>

									<OpenSpotify />

									<InfoButtons selectedGameMode={selectedGameMode} />
								</>
							) : (
								<div className="slide-in" style={{ position: "relative" }}>
									{displayEnterPinScreen ? (
										<InputField
											placeholder={"Pin"}
											value={pin}
											onNameChange={handlePinChange}
											shake={shake}
											goingBack={goingBack}
											transitioningForward={transitioningForward}
										/>
									) : (
										<InputField
											placeholder={"Your Name"}
											value={name}
											onNameChange={handleNameChange}
											shake={shake}
											goingBack={goingBack}
											transitioningForward={transitioningForward}
										/>
									)}

									{!displayEnterPinScreen && (
										<button
											onClick={handleCreateGame}
											className={`${styles.create} ${styles.button}`}
										>
											{createGameSubmitted ? (
												<Loader color={"white"} />
											) : (
												"CREATE GAME"
											)}
										</button>
									)}

									<button
										onClick={handleJoinGame}
										className={`${styles.join} ${styles.button}`}
									>
										{joinSubmitted ? <Loader color={"white"} /> : "JOIN GAME"}
										{/* JOIN GAME */}
									</button>

									{displayEnterPinScreen ? (
										<button
											className={`${styles.back} ${styles.button}`}
											onClick={goBackToEnterNameScreen}
										>
											Back
										</button>
									) : (
										""
									)}
									<InfoButtons selectedGameMode={selectedGameMode} />
									<div style={{ height: "20px" }}></div>
									<CurrentPlaylist
										requiresLogin={requiresLogin}
										logout={logout}
									/>
								</div>
							)}

							{/* {(token || (loggedInWithPlaylist && requiresLogin)) && (
							<LogoutButton logout={logout} />
						)} */}
							<div className={styles.bottomPanel}>
								{(token || (loggedInWithPlaylist && requiresLogin)) && (
									<LogoutButton logout={logout} />
								)}
							</div>
							{/* <div className={styles.bottomPanel}>
							<> </>
						</div> */}
							{/* <div className={styles.bottomPanel}>
							<> </>
						</div> */}
							<div className={styles.bottomPanel}>
								<ContactButton />
							</div>

							{/* <ContactButton />
						<div style={{ fontSize: "14px", color: "lightGray" }}>
							Contact Us
						</div> */}
						</>
					)}
					{/* <a
					style={{
						color: "lightGray",
						textDecoration: "none",
						marginTop: "15px",
					}}
					href="/"
				>
					Home
				</a> */}
					{/* <GoogleAd /> */}
				</PhoneDisplayPanel>
			</div>
		</>
	);
};

export default LandingPage;
