import { useEffect, useState } from "react";

const SPOTIFY_OPTIONS = [
	// { name: "Try Using Your Top Songs 2016 Playlist!", link: "spotify://" },
	// { name: "Try Using Your Top Songs 2017 Playlist!", link: "spotify://" },
	// { name: "Try Using Your Top Songs 2018 Playlist!", link: "spotify://" },
	// { name: "Try Using Your Top Songs 2019 Playlist!", link: "spotify://" },
	// { name: "Try Using Your Top Songs 2020 Playlist!", link: "spotify://" },
	// { name: "Try Using Your Top Songs 2021 Playlist!", link: "spotify://" },
	{
		name: "Try With Your Top Songs 2023 Playlist!",
		link: "spotify:playlist:37i9dQZF1Fa1IIVtEpGUcU",
	},
	// {
	// 	name: "Try Using Your Workout Rap Trap Mix!",
	// 	link: "spotify:playlist:37i9dQZF1EIfDafwVer0Ij",
	// },
	// {
	// 	name: "Try Using Your Chill Morning Smooth Mix!",
	// 	link: "spotify:playlist:37i9dQZF1EIeQIRX5EofPq",
	// },
	// {
	// 	name: "Try Using Your Angst Mix!",
	// 	link: "spotify:playlist:37i9dQZF1EIeNoBMmwUjGA",
	// },
	// {
	// 	name: "Try Using Your Elevator Music Mix!",
	// 	link: "spotify:playlist:37i9dQZF1EIdO8LqmDczC3",
	// },
	// {
	// 	name: "Try Using Your Sad Crying Mix!",
	// 	link: "spotify:playlist:37i9dQZF1EIdChYeHNDfK5",
	// },
];

export default function OpenSpotify() {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isPageVisible, setIsPageVisible] = useState(
		document.visibilityState === "visible"
	);

	let defaultSpotifyOption = {
		name: "Open Spotify to Share Playlist Link",
		link: "spotify:playlist:37i9dQZF1Fa1IIVtEpGUcU",
	};

	const shuffleArray = (array) => {
		if (!array.length || array.length === 0) {
			return array;
		}

		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}

		return [...array];
	};
	//open a popup that says, "search Top Songs 2016"
	const [suggestions] = useState(() => {
		const shuffled = shuffleArray([...SPOTIFY_OPTIONS]);
		return [defaultSpotifyOption, ...shuffled, defaultSpotifyOption];
	});

	useEffect(() => {
		const handleVisibilityChange = () => {
			console.log(document.visibilityState);
			setIsPageVisible(document.visibilityState === "visible");
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPageVisible) {
				setCurrentIndex((prev) => (prev + 1) % suggestions.length);
			}
		}, 6000);

		// Cleanup function to clear the interval on component unmount
		return () => clearInterval(interval);
	}, [isPageVisible, suggestions.length]);

	return (
		<>
			<a
				href={true ? suggestions[currentIndex].link : "spotify://"}
				style={styles.link}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						width: suggestions.length * 100 + "%",
						position: "absolute",
						left: currentIndex * -100 + "%",
						transition: currentIndex !== 0 ? "left 1.5s ease" : "",
					}}
				>
					<style>{styles.linkAnimation}</style>
					{suggestions.map((suggestion, index) => {
						return (
							<div
								key={index}
								style={{
									width: "100%",
									animation: "paddingAnimation 2s infinite",
								}}
							>
								{suggestion.name}
							</div>
						);
					})}
				</div>
			</a>
		</>
	);
}

const styles = {
	link: {
		position: "relative",
		width: "95%",
		marginTop: "5px",
		marginBottom: "5px",
		fontSize: "15px",
		fontWeight: "600",
		color: "#1DB954",
		textAlign: "center",
		textDecoration: "none",
		border: "1px solid #1DB954",
		borderRadius: "5px",
		padding: "10px",
		overflow: "hidden",
		height: "18px",
	},
	linkAnimation: `
	  @keyframes paddingAnimation {
		0% {
		  font-weight: 600;
		}
		50% {
		  font-weight: 800;
		}
		100% {
		  font-weight: 600;
		}
	  }
	`,
	slideIn: {
		width: "100%",
		animation: "slideInFromRight 1s ease-out",
	},
	slideInAnimation: `
	  @keyframes slideInFromRight {
		from {
		  transform: translateX(100%);
		}
		to {
		  transform: translateX(0);
		}
	  }
	`,
};
