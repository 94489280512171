import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SongInfo = ({ song, artist, uri, blindMode, correctNames }) => {
	const titleStyle = {
		fontWeight: "600",
		fontSize: "24px",
		textDecoration: "none",
		marginBottom: 0,
		width: "100%",
		marginTop: 0,
		// filter: "blur(8px)",
		animation: "underlineAnimation 1.5s forwards",
	};

	const artistStyle = {
		textAlign: "left",
		color: "lightGrey",
		width: "100%",
		marginTop: 5,
		marginBottom: 0,
	};

	const containerStyle = {
		flex: 1,
	};

	function spotifyUriToLink(uri) {
		if (uri) {
			const baseUrl = "https://open.spotify.com/";
			const [type, id] = uri.split(":").slice(1);
			return `${baseUrl}${type}/${id}`;
		} else {
			return "";
		}
	}

	return (
		<div style={containerStyle}>
			<style>
				{`
          @keyframes underlineAnimation {
  0% {
    text-decoration: none;
  }
  50% {
    text-decoration: none;
  }
  100% {
    text-decoration: underline;
  }
}
        `}
			</style>
			{uri ? (
				<a
					target={"_blank"}
					href={spotifyUriToLink(uri)}
					style={titleStyle}
					className={"text"}
				>
					{blindMode && !correctNames ? "??????" : song}
				</a>
			) : (
				<h2 style={titleStyle} className={"text"}>
					{song}
				</h2>
			)}

			<p style={artistStyle} className={"text"}>
				{blindMode && !correctNames ? "??" : artist}
				{/* {artist} */}
			</p>
		</div>
	);
};

const SongDisplayPanel = ({
	uri,
	image,
	song,
	artist,
	handleSubmit,
	selectedItems,
	voteSubmitted,
	blindMode,
	correctNames,
}) => {
	// const blindMode = false;
	const imageStyle = {
		flex: 1,
		borderRadius: "5%", // This sets the border-radius to create rounded corners
		width: "100%", // Set the maximum width to 100%
		transition: "filter 1s ease", // Add a smooth transition for the filter
		filter: "blur(0px)", // No blur when correctNames is true
	};

	const blurImageStyle = {
		flex: 1,
		borderRadius: "5%", // This sets the border-radius to create rounded corners
		width: "100%", // Set the maximum width to 100%
		filter: "blur(70px)", // Apply blur initially
		transition: "filter 1s ease", // Smooth transition on blur change
	};

	const containerStyle = {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		// paddingInline: "15px",
		padding: "10px",
		paddingTop: "20px",
		paddingBottom: "12px",
		borderRadius: "10px",
		width: "92%",

		background: "#222222",
	};

	//todo gradient

	// Determine whether the button should be disabled
	const isButtonDisabled = selectedItems.length === 0 || voteSubmitted;

	// Apply a grayed-out style to the button when it's disabled
	const buttonStyle = {
		// width: '50%',
		// marginTop: '10px',
		// height: '200%',
		paddingLeft: "20px",
		paddingRight: "20px",
		minHeight: "50px",
		background: !isButtonDisabled ? "lightgray" : "#222222",
		color: "#222222",
		borderWidth: "0px",
		borderRadius: "10px",
		fontWeight: "bold",
		opacity: !isButtonDisabled ? "1" : "0",
		// alignSelf: 'bottom',
	};

	const submittedStyle = {
		...buttonStyle,
		opacity: "0",
	};

	return (
		<div style={containerStyle}>
			<div
				style={{ overflow: "hidden", borderRadius: "5%", position: "relative" }}
			>
				{blindMode && !correctNames ? (
					<>
						<img
							src={image}
							// src={"../../images/question.png"}
							alt="Unknown Album Cover"
							style={blurImageStyle}
						/>
						<img
							// src={image}
							src={"../../images/asongusOutline.png"}
							alt="Unknown Album Cover"
							style={{
								position: "absolute", // Overlay the second image
								top: "50%", // Position it at the center (adjust as needed)
								left: "50%",
								transform: "translate(-50%, -50%)", // Center the overlay image
								borderRadius: "5%", // Optional styling
								width: "100%", // Set the size of the overlay image
								height: "100%",
								opacity: "0.5",

								transition: "opacity 0.5s ease", // Smooth transition on blur change
							}}
						/>
					</>
				) : (
					//do the transparency transition
					<>
						<img src={image} alt="Album Cover" style={imageStyle} />
						<img
							// src={image}
							src={"../../images/asongusOutline.png"}
							alt="Unknown Album Cover"
							style={{
								position: "absolute", // Overlay the second image
								top: "50%", // Position it at the center (adjust as needed)
								left: "50%",
								transform: "translate(-50%, -50%)", // Center the overlay image
								borderRadius: "5%", // Optional styling
								width: "100%", // Set the size of the overlay image
								height: "100%",
								opacity: "0",
								transition: "opacity 0.5s ease", // Smooth transition on blur change
							}}
						/>
					</>
				)}
			</div>
			<div
				style={{
					display: "flex",
					width: "100%",
					alignItems: "center",
					marginTop: "10px",
					// paddingLeft: "15px",
				}}
			>
				<SongInfo
					uri={uri}
					song={song}
					artist={artist}
					blindMode={blindMode}
					correctNames={correctNames}
				/>
				<button
					onClick={handleSubmit}
					style={voteSubmitted ? submittedStyle : buttonStyle}
					disabled={isButtonDisabled} // Disable the button based on the condition
				>
					GUESS
				</button>
			</div>
		</div>
	);
};

export default SongDisplayPanel;
