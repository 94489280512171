import React, { useEffect, useState } from "react";
import LogoutButton from "../Screens/MainMenu/LogoutButton";

const CurrentPlaylist = ({ requiresLogin, logout }) => {
	const [playlistTitle, setPlaylistTitle] = useState(null);

	// Use useEffect to read from localStorage when the component mounts
	useEffect(() => {
		// Get playlist data from localStorage
		const storedPlaylistTitle = localStorage.getItem("title");
		if (storedPlaylistTitle) {
			// Parse the stored playlist and update the state
			setPlaylistTitle(storedPlaylistTitle);
		}
	}, []);

	// Function to handle playlist switching
	const switchPlaylist = () => {
		// Logic to switch the playlist (you can update this)
		console.log("Switch playlist clicked");
	};

	return playlistTitle && requiresLogin ? (
		<div
			style={{
				color: "lightgray",
				textAlign: "center",
				// background: "green",
				width: "100%",
				// alignSelf: "center",
				// background: "#222222",
				borderRadius: "10px",
				paddingBlock: "10px",
				// maxWidth: "95%",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div style={{ marginRight: "5px" }}>{"Playlist: "} </div>

				<div style={{ color: "", textDecoration: "underline" }}>
					{" "}
					{playlistTitle.toString()}
				</div>
				<button
					onClick={logout}
					style={{
						cursor: "pointer",
						// marginLeft: "4px",
						border: "0px solid lightgray",
						background: "transparent",
						display: "flex",
						// alignItems: "flex-end",
						paddingInline: "5px",
					}}
				>
					<img
						src="../../images/exchangewhite.png"
						style={{
							borderRadius: "5px",
							// border: "1px solid lightgray",
							// paddingBlock: "2px",
							// marginLeft: "2px",
							// paddingInline: "6px",
							width: "17px",
							height: "17px", // Added height here
							alignSelf: "center",
							// background: "linear-gradient(0deg, black, gray)", // Greenish-black gradient
						}}
					/>
				</button>
			</div>

			{/* <LogoutButton logout={logout} /> */}

			{/* Button to switch playlists */}
		</div>
	) : (
		""
	);
};

export default CurrentPlaylist;
